export default {
  data: () => ({
    userreviews: {
      id: 0,
      name_of_user: "",
      rating: "",
      date_of_post: "",
      departure: "",
      destination: "",
      airline: "",
      platform: "",
      booking_class: "",
    },
    loading: false,
    error: "",
    departurelist: [],
    airlinenameslist: [],
    backUrl: "/user-review",
  }),
  methods: {
    resetForm() {
      this.$refs.observer.reset();
      this.userreviews = {
        id: 0,
        name_of_user: "",
        rating: "",
        date_of_post: "",
        departure: "",
        destination: "",
        airline: "",
        platform: "",
        booking_class: "",
      };
    },
    validatereview() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 10000);
      this.userreviews.id > 0 ? this.update() : this.add();
    },
    update() {
      let _vm = this;
      this.axios
        .post("/user-reviews/" + this.userreviews.id, {
          ...this.userreviews,
          _method: "PATCH",
        })
        .then(function (response) {
          _vm.userreviews = response.data.data;
          _vm.$router.push(_vm.backUrl);
        })
        .catch(function () {});
    },
    add() {
      let _vm = this;
      this.axios
        .post("/user-reviews", _vm.userreviews)
        .then(function (response) {
          _vm.$router.push(_vm.backUrl);
        })
        .catch(function () {});
    },

    getQueryString() {
      let queryString = "?search=";
      return queryString;
    },
    getDetail() {
      let _vm = this;
      this.axios
        .get("/user-reviews/" + this.$route.params.id)
        .then(function (response) {
          _vm.userreviews = response.data.data;
        })
        .catch(function () {});
    },
    getCity() {
      let _vm = this;
      this.axios
        .get("/user-reviews-city-name-code", _vm.country)
        .then(function (response) {
          _vm.departurelist = response.data.data;
        })
        .catch(function () {});
    },
    getAirlineNames() {
      let _vm = this;
      this.axios
        .get("/airline-list")
        .then(function (response) {
          _vm.airlinenameslist = response.data.data;
        })
        .catch(function () {});
    },
  },
  mounted() {
    if (this.$route.params.id > 0) {
      this.getDetail();
    }
    this.getCity();
    this.getAirlineNames();
  },
};
